<template>
  <div class="page">
    <div class="sticky-top">
      <Header />
      <Menu />
    </div>
    <div class="page-wrapper">

      <router-view :key="$route.fullPath"></router-view>

      <footer class="footer footer-transparent d-print-none">
        <div class="container-xl">
          <div class="col-6 col-lg-auto mt-3 mt-lg-0">
            <div>
              Copyright &copy; 2023 - 2025. Все права защищены.
            </div>
            <div class="mt-2">
              Build Core: <b>v09-03-25.1</b>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header"
import Menu from "@/components/menu/Menu"
import {useRoute} from "vue-router"
import {defineComponent} from "vue"

export default defineComponent({
  name: "Default",
  components: {
    Header,
    Menu,
  },
  setup() {
    const route = useRoute()

    return {
      route,
    }
  }
})
</script>
